import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';

import {Button, IconButton, Input, Link, Modal, Tooltip, Typography} from '@components/index';
import {TelegramIcon, WhatsAppIcon} from '@src/components/common/icons';
import {COLORS} from '@src/shared/const/appPalette';
import {IS_SENDED_USER_CONTACTS} from '@src/shared/const/localStorage';
import {RESOURCE_URLS} from '@src/shared/const/resource-urls';

import {useStore} from '../../../../stores';

import {useEmotionWrapper} from './user-contacts.s';

export const UserContactsModal = observer(() => {
  const {classes} = useEmotionWrapper();
  const {utils, user, mpAccounts} = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [userContacts, setUserContacts] = useState({
    name: '',
    phone: '',
    telegramUsername: '',
  });

  const onCloseModal = () => {
    utils.closeOfferTrialModal();
  };

  const onSubmitUserContacts = () => {
    setIsLoading(true);
    user
      .addUserContacts(userContacts)
      .then(() => {
        toast.success('Спасибо! Свяжемся с вами в рабочее время');
        localStorage.setItem(IS_SENDED_USER_CONTACTS, 'true');
        utils.closeOfferTrialModal();
      })
      .catch(() => toast.error('Контакты не отправлены'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (
      mpAccounts.current?.hadPaidTariff === false &&
      user._init &&
      !window.location.pathname?.includes('/yandex-auth')
    ) {
      setTimeout(() => {
        utils.openOfferTrialModal();
      }, 2000);
    }
  }, [user._init, mpAccounts.current?.tariff]);

  return (
    <Modal opened={utils.isOpenOfferTrialModal} onClose={onCloseModal}>
      <div className={classes.root}>
        <Typography size={18} weight={600} align="center">
          Оставьте заявку и получите доступ к демо периоду
        </Typography>
        <div className={classes.formBlock}>
          <div className={classes.inputContainer}>
            <Typography weight={500} size={13} color={COLORS.BLACK_50}>
              Имя
            </Typography>
            <Input
              disabled={isLoading}
              value={userContacts.name}
              onChange={(e) => setUserContacts({...userContacts, name: e.target.value})}
              width={340}
              placeholder="Ваше Имя"
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography weight={500} size={13} color={COLORS.BLACK_50}>
              Номер телефона
            </Typography>
            <Input
              disabled={isLoading}
              type="tel"
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              value={userContacts.phone}
              onChange={(e) => setUserContacts({...userContacts, phone: e.target.value})}
              width={340}
              placeholder="Телефон"
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography weight={500} size={13} color={COLORS.BLACK_50}>
              Ник в telegram (по желанию)
            </Typography>
            <Input
              disabled={isLoading}
              value={userContacts.telegramUsername}
              onChange={(e) => setUserContacts({...userContacts, telegramUsername: e.target.value})}
              width={340}
              placeholder="Ник в telegram (по желанию)"
            />
          </div>
          <Tooltip
            destroy={userContacts.name.length > 0 && userContacts.phone.length > 0}
            content="Ваше имя и телефон обязательны"
          >
            <Button
              style={{width: 340}}
              loading={isLoading}
              onClick={onSubmitUserContacts}
              disabled={!userContacts.name || !userContacts.phone}
              variant="outlined"
              color={COLORS.GREEN_100}
            >
              Оставить заявку
            </Button>
          </Tooltip>
          <Typography weight={500} size={16} align="center">
            Мы с вами быстро свяжемся в рабочее время, а на выходных чуть медленнее)
          </Typography>
          <Typography align="center" color={COLORS.BLACK_50} size={13}>
            *Отправляя заявку Вы даете согласие на обработку{' '}
            <Link target="_blank" href={RESOURCE_URLS.PRAVO}>
              персональных данных
            </Link>
          </Typography>
        </div>
        <div className={classes.phoneContactContainer}>
          <div className={classes.phoneContactTitle}>
            <Typography weight={500} size={15} align="center">
              Написать нам
            </Typography>
            <div style={{marginLeft: 10}}>
              <IconButton onClick={() => window.open('https://wa.me/+79677736350', '_blank')} size="large">
                <WhatsAppIcon />
              </IconButton>
              <IconButton onClick={() => window.open(RESOURCE_URLS.SUPPORT_TELEGRAM, '_blank')} size="large">
                <TelegramIcon />
              </IconButton>
            </div>
          </div>
          <div className={classes.phoneContact}>
            <Typography weight={500} size={15}>
              Позвонить нам
            </Typography>
            <a href="tel:+79677736350">+7 967 773 63 50</a>
          </div>
        </div>
      </div>
    </Modal>
  );
});
