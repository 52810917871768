import {observer} from 'mobx-react-lite';

import {ChangelogModal} from '@src/components/specific/modals/changelog-modal/changelog-modal';
import {ReferralModal} from '@src/components/specific/modals/referral-modal/referral-modal';
import {UserContactsModal} from '@src/components/specific/modals/user-contacts/user-contacts';

export const ModalsManager = observer(() => {
  return (
    <>
      <UserContactsModal />
      <ChangelogModal />
      <ReferralModal />
    </>
  );
});
