import {createTheme} from '@mui/material';
import {red} from '@mui/material/colors';

import {COLORS_REDESIGN} from '../const/appPalette';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h2: {
      color: COLORS_REDESIGN.DARK_NIGHT_BLUE,
      fontWeight: 600,
    },
    allVariants: {
      color: '#344054',
    },
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
          th: {
            fontWeight: 500,
            color: COLORS_REDESIGN.TEXT_PRIMARY,
            '&:first-of-type': {
              borderRadius: '8px 0 0 0',
            },
            '&:last-child': {
              borderRadius: '0 8px 0 0',
            },
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          color: COLORS_REDESIGN.TEXT_PRIMARY,
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
          width: 'fit-content',
          borderRadius: 12,
          padding: '10px 20px',
        },
        arrow: {
          color: 'white',
          boxShadow: '0 0 30px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        // select: {
        //   '&&.MuiInputBase-input': {
        //     paddingRight: '30px',
        //   },
        // },
        root: {
          // backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
          },
        },
        // select: {
        //   backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
        //   '&:focus': {
        //     backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
        //   },
        // },
        // icon: {
        //   color: COLORS_REDESIGN.BRAND,
        //   backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
        // },
      },
    },
    // MuiSelect: {
    //   defaultProps: {
    //     MenuProps: {
    //       sx: {
    //         '& fieldset': {
    //           border: 'none',
    //         },
    //         '& .MuiPaper-root': {
    //           backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
    //         },
    //       },
    //     },
    //   },
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
    //     },
    //     select: {
    //       backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
    //       '&:focus': {
    //         backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
    //       },
    //     },
    //     icon: {
    //       color: COLORS_REDESIGN.BRAND,
    //       backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
    //     },
    //   },
    // },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            // backgroundColor: COLORS_REDESIGN.LIGHT_GREY,
            // '& fieldset': {
            //   border: 'none',
            // },
            // borderRadius: 8,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: COLORS_REDESIGN.BRAND,
      light: '#855ad1',
      dark: '#855ad1',
    },
    secondary: {
      main: '#19857b',
      light: '#19857b',
      dark: '#19857b',
    },
    warning: {
      main: '#ffc107',
      light: '#ffc107',
      dark: '#ffc107',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
